import React from 'react';
import bemCn from 'bem-cn';
import { mainMenu } from './consts';
import CookieService from '../../services/cookie.service';
import { SIGN_UP_SRC } from '../../consts/profile.const';
import { RouteFormatter } from '../../routes';
import { savePathRedirect } from '../../helpers/cookies';
import { COOKIE_PARAM_NAME } from '../../consts/cookies.consts';
import { RenderBlogs, RenderDogBreeds, RenderDogNames, RenderDogRescues, RenderSubParks, RenderTrainers } from './shared';
import SniffButton from '../sniff-button';
import useMutateViewBlurSignup from '../../services/hooks/useMutateViewBlurSignup';
import SvgSnifLogo from 'src/assets/svg/SvgSnifLogo.svg';
import './style.scss';

const b = bemCn('nav-guest-unauth');

const NavigationGuestUnauth = ({ className = '', setIsBlurTest = () => {} }) => {
    const { checkBlurSignupTest } = useMutateViewBlurSignup();

    const onClick = async (e, type) => {
        e.preventDefault();
        if (type === 'signIn') {
            window.location.href = RouteFormatter.signIn();
        } else if (type === 'signUp') {
            const isBlurTest = await checkBlurSignupTest();
            if (isBlurTest) {
                setIsBlurTest(true);
            } else {
                window.location.href = RouteFormatter.signUp();
            }
        }
        savePathRedirect();
        CookieService.set(COOKIE_PARAM_NAME.SIGN_UP_FROM, SIGN_UP_SRC.NAV_WEB, { expires: 1 });
    };

    return (
        <header className={b.mix(className)}>
            <div className={b('left')}>
                <a href={RouteFormatter.home()} className={b('logo')}>
                    <SvgSnifLogo />
                </a>
                {mainMenu.map((v, i) => (
                    <div key={i} className={b('main-menu').mix(`snif-p ${v.id}`)}>
                        <a href={v.url}>{v.name}</a>
                        {v.id === 'DP' && <RenderSubParks />}
                        {v.id === 'BG' && <RenderBlogs />}
                        {v.id === 'DN' && <RenderDogNames />}
                        {v.id === 'DR' && <RenderDogRescues />}
                        {v.id === 'TR' && <RenderTrainers />}
                        {v.id === 'DB' && <RenderDogBreeds />}
                    </div>
                ))}
            </div>
            <div className={b('right')}>
                <a href={RouteFormatter.signIn()} onClick={(e) => onClick(e, 'signIn')}>
                    <SniffButton style={{ border: 'none' }} color="secondary">
                        Sign in
                    </SniffButton>
                </a>
                <a href={RouteFormatter.signUp()} onClick={(e) => onClick(e, 'signUp')}>
                    <SniffButton>Sign up</SniffButton>
                </a>
            </div>
        </header>
    );
};

export default NavigationGuestUnauth;
