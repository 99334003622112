import React from 'react';
import isbot from 'isbot';
import bemCn from 'bem-cn';
import loadable from '@loadable/component';
import CookieService from '../../services/cookie.service';
import { NAV_ITEMS } from '../navigation/navigation.consts';
import { RouteFormatter } from '../../routes';
import { COOKIE_PARAM_NAME } from '../../consts/cookies.consts';
import RenderMobileDefaultRightSSR from './default-right-ssr';
import NavigationGuestUnauth from '../navigation-guest-unauth';
import NavigationLayoutSSR from '../navigation-layout/layout-ssr';
import NavigationLogoSSR from '../navigation-logo/nav-logo-ssr';
import HeaderLogoWrapper from '../header-logo-wrapper';
import SignupPopupSeo from '../signup-popup-seo';
import SmartBanner from '../smart-banner';
import BlurSignupModal from '../modal-blur-signup';
import './style.scss';

const SignupPopupSeoSpotAlert = loadable(() => import('../signup-popup-seo-spot-alert'));

const b = bemCn('navigation-guest');

const NavigationSSR = ({
    userDetails,
    isMobileSSR: isMobile,
    useState,
    useEffect,
    useMemo,
    pathname,
    isSpotPage,
    spot,
    useGetShareLink,
    useCallback,
    useMutateFavorites,
    useGetFavorites,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isBlurTest, setIsBlurTest] = useState(null);
    const [navClass, setNavClass] = useState('mob-show d-flex d-md-none');
    const [styleClient, setStyleClient] = useState(null);
    const isAuthenticated = !!userDetails?.id;
    const hasSpots = isAuthenticated && userDetails.spots?.length > 0;
    const hasUnconsumedMessage = [];
    const isBlog = pathname == RouteFormatter.blog();
    const isListing =
        pathname == RouteFormatter.listings({}) || pathname == RouteFormatter.listings2({}) || pathname == RouteFormatter.listingsVideo({});
    const isBlogSub = pathname.startsWith('/blog/');

    const toggleMenu = (e) => {
        e?.nativeEvent?.stopImmediatePropagation();
        setIsOpen(!isOpen);
    };

    const navigationConfig = useMemo(
        () =>
            [
                !isAuthenticated && NAV_ITEMS.LOGIN,
                isAuthenticated && NAV_ITEMS.VISIT_LIST,
                NAV_ITEMS.SUBSCRIPTIONS,
                isAuthenticated && { ...NAV_ITEMS.GUEST_MESSAGES, notification: hasUnconsumedMessage.length > 0 },
                isAuthenticated && NAV_ITEMS.ACCOUNT,
                NAV_ITEMS.EXPLORE_SPOTS,
                isAuthenticated && NAV_ITEMS.INVITE_FRIENDS,
                isAuthenticated && NAV_ITEMS.FAVORITES,
                hasSpots ? NAV_ITEMS.SWITCH_TO_HOST : NAV_ITEMS.HOST_WITH_SNIFF,
                NAV_ITEMS.SEPARATOR,
                NAV_ITEMS.TRUST_SAFETY,
                NAV_ITEMS.HELP_CENTER,
                NAV_ITEMS.BLOG,
                isAuthenticated && NAV_ITEMS.SEPARATOR,
                isAuthenticated && NAV_ITEMS.SIGN_OUT,
            ].filter((item) => !!item),
        [isAuthenticated, userDetails, hasUnconsumedMessage]
    );

    useEffect(() => {
        if (userDetails?.id && userDetails?.latitude && userDetails?.longitude) {
            const dataUser = {
                location: { lat: userDetails.latitude, lng: userDetails.longitude },
                userLocationConfirm: true,
            };
            CookieService.set('user', JSON.stringify(dataUser));
        }
    }, [userDetails, isAuthenticated]);

    useEffect(() => {
        if (isMobile) {
            const htmlEle = document.documentElement;
            if (isOpen) {
                htmlEle.classList.add('root-html-focused');
            } else {
                htmlEle.classList.remove('root-html-focused');
            }
            return () => htmlEle.classList.remove('root-html-focused');
        }
    }, [isMobile, isOpen]);

    useEffect(() => {
        const isBotCrawl = isbot(navigator.userAgent);
        const isSpotTestPresent = CookieService.get(COOKIE_PARAM_NAME.SPOT_DETAIL_4686_TEST);
        !isBotCrawl && setNavClass(`mob-show d-flex d-md-none${isSpotTestPresent == 'NEW' ? ' spot-test-present' : ''}`);
        const topBarHeight = isListing ? { height: '72px' } : { height: '56px', flexDirection: 'column' };
        setStyleClient(topBarHeight);
    }, []);

    if (isMobile === null) {
        return '';
    }

    // WHEN IN MOBILE
    return (
        <>
            <SmartBanner />
            <SignupPopupSeo />
            <SignupPopupSeoSpotAlert />
            <NavigationGuestUnauth setIsBlurTest={setIsBlurTest} className="d-none d-md-flex" />
            <NavigationLayoutSSR className={navClass} style={styleClient}>
                <div className="w-100 h-100 d-flex justify-content-between align-items-center">
                    <div className={b('left')}>
                        {!isListing && <NavigationLogoSSR {...{ isMobile, useState, isSpotPage, pathname, spot }} full />}
                        {/* WHEN IN LISTING */}
                        {isListing && (
                            <HeaderLogoWrapper
                                toggleMenu={toggleMenu}
                                isMenuOpened={isOpen}
                                isAuthenticated={false}
                                className="listing-unauth-menu"
                                isMobileSSR={isMobile}
                                userDetails={userDetails}
                                useState={useState}
                                useEffect={useEffect}
                                isListing={isListing}
                                SSR
                            />
                        )}
                    </div>
                    {!isListing && (
                        <RenderMobileDefaultRightSSR
                            {...{
                                setIsBlurTest,
                                navigationConfig,
                                toggleMenu,
                                isMenuOpened: isOpen,
                                isAuthenticated,
                                isMobile,
                                useState,
                                userDetails,
                                useEffect,
                                isSpotPage,
                                spot,
                                useGetShareLink,
                                useCallback,
                                useMutateFavorites,
                                useGetFavorites,
                                isBlog,
                                isBlogSub,
                            }}
                        />
                    )}
                </div>
            </NavigationLayoutSSR>
            <BlurSignupModal
                {...{
                    isBlurTest,
                    setIsBlurTest,
                    isMobile,
                    useStateSSR: useState,
                }}
            />
        </>
    );
};

export default NavigationSSR;
