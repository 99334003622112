import React from 'react';
import bemCn from 'bem-cn';
import CookieService from '../../services/cookie.service';
import { SIGN_UP_SRC } from '../../consts/profile.const';
import { RouteFormatter } from '../../routes';
import { savePathRedirect } from '../../helpers/cookies';
import { COOKIE_PARAM_NAME } from '../../consts/cookies.consts';
import NavGuestMobileUnauthSSR from '../navigation-guest-unauth/mobile-ssr';
import SniffButton from '../sniff-button';
import SvgBarsSolid from 'src/assets/svg/SvgBarsSolid.svg';
import useMutateViewBlurSignup from '../../services/hooks/useMutateViewBlurSignup';
import SvgCross2 from 'src/assets/svg/SvgCross2.svg';
import './style.scss';

const b = bemCn('header-logo-wrapper');

const HeaderLogoWrapper = ({ toggleMenu, isMenuOpened, className, useState, isListing, isSpotPage, setIsBlurTest }) => {
    const { checkBlurSignupTest } = useMutateViewBlurSignup();
    const onClick = async (e) => {
        e.preventDefault();
        savePathRedirect();
        CookieService.set(COOKIE_PARAM_NAME.SIGN_UP_FROM, SIGN_UP_SRC.NAV_WEB, { expires: 1 });
        const isBlurTest = await checkBlurSignupTest();

        if (isBlurTest) {
            setIsBlurTest(true);
        } else {
            window.location.href = RouteFormatter.signUp();
        }
    };

    return (
        <>
            {!isMenuOpened && !isSpotPage && (
                <a href={RouteFormatter.signUp()} onClick={onClick}>
                    <SniffButton size="sm" style={{ whiteSpace: 'nowrap', marginRight: '8px' }}>
                        Sign up
                    </SniffButton>
                </a>
            )}
            <div className={b.mix(className)} onClick={toggleMenu}>
                {isMenuOpened ? <SvgCross2 width={24} height={24} /> : <SvgBarsSolid width={24} height={24} />}
            </div>
            <NavGuestMobileUnauthSSR active={isMenuOpened} handleLeave={toggleMenu} {...{ isListing, isSpotPage, useState }} />
        </>
    );
};

export default HeaderLogoWrapper;
