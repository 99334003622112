import React from 'react';
import { Slide, toast, ToastContainer } from 'react-toastify';
import './index.scss';

const toastOpt = {
    position: 'bottom-right',
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
};

export const toasterInfo = (content = '') => toast.info(content, toastOpt);
export const toasterDark = (content = '') => toast.dark(content, toastOpt);

export const toasterSuccess = ({ title, sub }) =>
    toast.success(
        () => (
            <div>
                <div className="snif-p snif-semibold">{title}</div>
                {sub && <div className="snif-p mt-1">{sub}</div>}
            </div>
        ),
        toastOpt
    );

export const toasterDanger = ({ title, sub, addedOpt = {} }) => {
    const options = { ...toastOpt, ...addedOpt };

    return toast.error(
        () => (
            <div>
                <div className="snif-p snif-semibold">{title}</div>
                {sub && <div className="snif-p mt-1">{sub}</div>}
            </div>
        ),
        options
    );
};

const SniffToast = () => {
    return (
        <ToastContainer
            position="top-right"
            autoClose={2500}
            hideProgressBar={false}
            transition={Slide}
            newestOnTop
            closeOnClick
            pauseOnFocusLoss
            pauseOnHover={false}
            theme="colored"
            limit={3}
        />
    );
};

export default SniffToast;
